import React from "react";

const BotonUbicacion = ({ enfocarMapa }) => {
  const manejarClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (latitude && longitude) {
            enfocarMapa(latitude, longitude, 15); // Zoom deseado
          } else {
            console.warn("Ubicación no conseguida.");
          }
        },
        (error) => {
          console.error("No se pudo obtener la ubicación:", error);
        }
      );
    } else {
      console.warn("La geolocalización no está disponible en este navegador.");
    }
  };

  return (
    <>
      <style>
        {`
          * {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
          }

          .box {
            width: 50px;
            height: 50px;
            display: block;
            position: fixed; /* Cambiado a fixed para asegurar que siempre se posicione respecto al viewport */
            bottom: calc(10vh + 20px); /* Ajuste dinámico para compensar la barra del navegador */
            left: 40px;
            background: none;
            animation: updown 1s ease 0.2s infinite;
            z-index: 99;
            cursor: pointer;
          }

          .circle {
            position: relative;
            background-color: #000000; /* Negro */
            width: 50px;
            height: 50px;
            margin: auto;
            padding: 0;
            border-radius: 50%;
          }

          .inner {
            position: relative;
            background-color: white;
            width: 18px;
            height: 18px;
            margin: auto;
            padding: 0;
            top: 15px;
            border-radius: 50%;
            z-index: 3;
          }

          .square {
            position: relative;
            background-color: #000000; /* Negro */
            width: 25px;
            height: 25px;
            margin: auto;
            padding: 0;
            bottom: 20px;
            transform: rotate(-45deg);
            z-index: 2;
            border-bottom-left-radius: 3px;
          }

          @keyframes updown {
            0% {
              transform: translateY(0px);
            }

            50% {
              transform: translateY(4px);
            }

            100% {
              transform: translateY(0px);
            }
          }
        `}
      </style>
      <div className="box" onClick={manejarClick}>
        <div className="circle">
          <div className="inner"></div>
          <div className="highlight"></div>
        </div>
        <div className="square"></div>
      </div>
    </>
  );
};

export default BotonUbicacion;
