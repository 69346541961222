
import React, { useState, useEffect } from "react";
import PuntoFlotante from "./PuntoFlotante"; // Asegúrate de importar tu componente
import { emociones } from '../screens/EstadoUsuario';

const backendUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

// nuevo mferia


const ListaEstadisticas = ({ enfocarMapa, actualizarFiltroColor,canOpen,toggle,isVisible }) => {
  const [estadisticas, setEstadisticas] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState("paises");
  const [filtroColor, setFiltroColor] = useState(null); // Color para filtrar estadísticas
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  

  const handleToggle = () => {
    if (canOpen) {
      setIsOpen(!isOpen); // Alterna el estado local
      toggle(); // Notifica al componente principal
    }
  };
  // Obtener estadísticas del backend al montar el componente
  useEffect(() => {
    const fetchEstadisticas = async () => {
      try {
        // const response = await fetch("/api/stats");
        const response = await fetch(`${backendUrl}/api/stats`);
        
        if (!response.ok) {
          throw new Error("Error al obtener estadísticas del backend.");
        }
        const data = await response.json();

        if (data?.stats?.topPaises && data?.stats?.topCiudades) {
          setEstadisticas(data.stats);
        } else {
          throw new Error("Formato de respuesta inválido del backend.");
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchEstadisticas();
  }, []);

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(window.innerHeight);
    };
  
    window.addEventListener("resize", updateViewportHeight);
  
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "20px",
          zIndex: 10,
          color: "white",
          fontFamily: "'Poppins', sans-serif",
          fontSize: "14px",
        }}
      >
        Cargando estadísticas...
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "20px",
          zIndex: 10,
          color: "red",
          fontFamily: "'Poppins', sans-serif",
          fontSize: "14px",
        }}
      >
        Error: {error}
      </div>
    );
  }

  // Obtener colores únicos de las estadísticas
  const coloresUnicos = [
    ...new Set([
      ...estadisticas.topPaises.map((pais) => pais.emocionDominante),
      ...estadisticas.topCiudades.map((ciudad) => ciudad.emocionDominante),
    ]),
  ];

  // Filtrar estadísticas por color seleccionado
  const filtrarEstadisticas = (items) => {
    const elementosFiltrados = filtroColor
      ? items.filter((item) => item.emocionDominante === filtroColor) // Filtrar por color si hay filtro
      : items;
  
    // Si no hay filtro, limitar a 10 elementos
    return filtroColor ? elementosFiltrados : elementosFiltrados.slice(0, 10);
  };
  

  return (
    <>
    {/* Pestaña para abrir/cerrar estadísticas */}
    {isVisible && ( // Solo renderiza si isVisible es true
      <div
        onClick={handleToggle}
        style={{
          position: "fixed",
          top: "50%",
          left: 0,
          width: "40px",
          height: "80px",
          backgroundColor: "#888",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: "0 10px 10px 0",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
          transform: "translateY(-50%)",
        }}
      >
        {isOpen ? "◂" : "▸"}
      </div>
    )}


      {/* Panel de estadísticas */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "300px",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 999,
            overflowY: "auto",
            padding: "20px",
            boxShadow: "2px 0 10px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Opciones para seleccionar vista */}
          <div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <button
                onClick={() => setView("paises")}
                style={{
                  flex: 1,
                  padding: "10px",
                  backgroundColor: view === "paises" ? "#ddd" : "#888",
                  color: view === "paises" ? "white" : "black",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px 0 0 5px",
                }}
              >
                Países
              </button>
              <button
                onClick={() => setView("ciudades")}
                style={{
                  flex: 1,
                  padding: "10px",
                  backgroundColor: view === "ciudades" ? "#ddd" : "#888",
                  color: view === "ciudades" ? "white" : "black",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                Ciudades
              </button>
            </div>

            {/* Lista de estadísticas */}
            {view === "paises" && estadisticas?.topPaises && (
              <div>
                <h3 style={{ marginBottom: "14px", textAlign: "center", color: "white" }}>
                  Top Países
                </h3>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {filtrarEstadisticas(estadisticas.topPaises).map((pais, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => setHoveredIndex(`pais-${index}`)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={() => enfocarMapa(pais.lat, pais.lng, 5)}
                      style={{
                        marginBottom: "6px",
                        color: pais.emocionDominante || "black",
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: hoveredIndex === `pais-${index}` ? "16px" : "14px",
                        backgroundColor:
                          hoveredIndex === `pais-${index}` ? "rgba(255, 255, 255, 0.1)" : "transparent",
                        borderRadius: "5px",
                        padding: hoveredIndex === `pais-${index}` ? "4px" : "0",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}. {pais.pais}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {view === "ciudades" && estadisticas?.topCiudades && (
              <div>
                <h3 style={{ marginBottom: "14px", textAlign: "center", color: "white" }}>
                  Top Ciudades
                </h3>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {filtrarEstadisticas(estadisticas.topCiudades).map((ciudad, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => setHoveredIndex(`ciudad-${index}`)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={() => enfocarMapa(ciudad.lat, ciudad.lng, 10)}
                      style={{
                        marginBottom: "6px",
                        color: ciudad.emocionDominante || "black",
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: hoveredIndex === `ciudad-${index}` ? "18px" : "14px",
                        backgroundColor:
                          hoveredIndex === `ciudad-${index}` ? "rgba(255, 255, 255, 0.1)" : "transparent",
                        borderRadius: "5px",
                        padding: hoveredIndex === `ciudad-${index}` ? "4px" : "0",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}. {ciudad.ciudad}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

         {/* Filtros con PuntoFlotante */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Permite distribuir los puntos en filas
            justifyContent: "center", // Centra los puntos horizontalmente
            gap: "10px", // Espaciado entre los puntos
            marginTop: "10px", // Espaciado superior
            padding: "10px", // Espaciado interno opcional
            marginBottom: `${viewportHeight * 0.08}px`, // Ajusta dinámicamente el margen inferior
          }}
        >
          {emociones.map((emocion, index) => (
            <div
              key={index}
              style={{
                flex: "0 0 calc(25% - 10px)", // Máximo 4 puntos por fila
                display: "flex",
                alignItems: "center", // Centra el punto verticalmente
                justifyContent: "center", // Centra el punto horizontalmente
                cursor: "pointer", // Indicador visual de que es interactivo
              }}
              onClick={() => {
                // Actualiza el filtro local en ListaEstadisticas
                setFiltroColor(emocion.color === filtroColor ? null : emocion.color);
                // Llama a la función principal para filtrar usuarios en App.js
                actualizarFiltroColor(emocion.color === filtroColor ? null : emocion.color);
              }} // Cambia el filtro al hacer clic
            >
              <PuntoFlotante
                color={emocion.color}
                size={emocion.color === filtroColor ? 50 : 40} // Cambia el tamaño si está seleccionado
                animationDuration={emocion.color === filtroColor ? 5 : 0} // Activa animación si está seleccionado
                hasParticles={false} // Sin partículas para el filtro
              />
            </div>
          ))}
        </div>

        </div>
      )}
    </>
  );
};

export default ListaEstadisticas;
