// import React, { useState } from "react";
// import Slider from "@mui/material/Slider";
// import PuntoFlotante from "../components/PuntoFlotante";

// const emociones = [
//   { nombre: "Enfado", color: "rgba(255, 0, 0, 0.8)" },
//   { nombre: "Calma", color: "rgba(0, 255, 0, 0.8)" },
//   { nombre: "Tristeza", color: "rgba(0, 0, 255, 0.8)" },
//   { nombre: "Felicidad", color: "rgba(255, 255, 0, 0.8)" },
//   { nombre: "Ansiedad", color: "rgba(255, 165, 0, 0.8)" },
//   { nombre: "Creatividad", color: "rgba(150, 75, 200, 0.8)" },
//   { nombre: "Amor", color: "rgba(255, 20, 147, 0.8)" },
//   { nombre: "Serenidad", color: "rgba(0, 255, 255, 0.8)" },
// ];

// const EstadoUsuario = ({ onSave }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [seleccion, setSeleccion] = useState(0);
//   const [size, setSize] = useState(5);

//   const emocionActual = emociones[seleccion];

//   const cambiarEmocion = (direccion) => {
//     const nuevoIndex = (seleccion + direccion + emociones.length) % emociones.length;
//     setSeleccion(nuevoIndex);
//   };

//   const handleSizeChange = (e, newValue) => {
//     setSize(newValue);
//   };

//   const handleSave = () => {
//     // Primero obtenemos la ubicación del usuario
//     if ("geolocation" in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           // Ahora llamamos a onSave con color, size y ubicación
//           const intensidad = size;
//           onSave(emocionActual.color, size * 4, intensidad, latitude, longitude);
//           setIsOpen(false);
//         },
//         (error) => {
//           console.error("No se pudo obtener la ubicación:", error);
//           // Incluso si falla, podrías llamar onSave sin ubicación 
//           // o con una ubicación por defecto
//           const intensidad = size;
//           onSave(emocionActual.color, size * 4,intensidad, null, null);
//           setIsOpen(false);
//         },
//         {
//           enableHighAccuracy: true,
//           timeout: 10000,
//           maximumAge: 0,
//         }
//       );
//     } else {
//       console.warn("Geolocalización no soportada.");
//       const intensidad = size;
//       onSave(emocionActual.color, size * 4, intensidad,null, null);
//       setIsOpen(false);
//     }
//   };

//   return (
//     <>
//       <div
//         onClick={() => setIsOpen(!isOpen)}
//         style={{
//           position: "fixed",
//           top: "50%",
//           right: 0,
//           width: "40px",
//           height: "80px",
//           backgroundColor: "#888",
//           color: "#fff",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           cursor: "pointer",
//           borderRadius: "10px 0 0 10px",
//           boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
//           zIndex: 1000,
//           transform: "translateY(-50%)",
//         }}
//       >
//         {isOpen ? ">" : "<"}
//       </div>

//       <div
//         style={{
//           position: "fixed",
//           top: "0",
//           right: isOpen ? "0" : "-300px",
//           width: "300px",
//           height: "100vh",
//           backgroundColor: "#fff",
//           boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
//           transition: "right 0.3s ease",
//           zIndex: 999,
//         }}
//       >
//         <div
//           style={{
//             height: "60%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <PuntoFlotante
//             color={emocionActual.color}
//             size={size * 20}
//             animationDuration={3 + Math.random() * 2}
//           />
//         </div>

//         <div
//           style={{
//             padding: "10px",
//             borderBottom: "1px solid #ccc",
//           }}
//         >
//           <p style={{ margin: "0 0 10px", textAlign: "center" }}>Intensidad</p>
//           <Slider
//             value={size}
//             onChange={handleSizeChange}
//             min={1}
//             max={10}
//             step={0.1}
//             valueLabelDisplay="auto"
//             style={{ color: "#444" }}
//           />
//         </div>

//         <div
//           style={{
//             textAlign: "center",
//             padding: "20px 0",
//             fontSize: "18px",
//             fontWeight: "bold",
//           }}
//         >
//           <button
//             onClick={() => cambiarEmocion(-1)}
//             style={{
//               background: "none",
//               border: "none",
//               fontSize: "18px",
//               cursor: "pointer",
//               marginRight: "10px",
//             }}
//           >
//             {"<"}
//           </button>
//           {emocionActual.nombre}
//           <button
//             onClick={() => cambiarEmocion(1)}
//             style={{
//               background: "none",
//               border: "none",
//               fontSize: "18px",
//               cursor: "pointer",
//               marginLeft: "10px",
//             }}
//           >
//             {">"}
//           </button>
//         </div>

//         <div style={{ padding: "20px", textAlign: "center" }}>
//           <button
//             onClick={handleSave}
//             style={{
//               width: "100%",
//               padding: "10px",
//               backgroundColor: "#444",
//               color: "#fff",
//               border: "none",
//               borderRadius: "5px",
//               cursor: "pointer",
//             }}
//           >
//             Aplicar
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default EstadoUsuario;

import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import PuntoFlotante from "../components/PuntoFlotante";

export const emociones = [
  { nombre: "Enfado", color: "rgba(255, 0, 0, 0.8)" },
  { nombre: "Soledad", color: "rgba(0, 255, 0, 0.8)" },
  { nombre: "Tristeza", color: "rgba(0, 0, 255, 0.8)" },
  { nombre: "Felicidad", color: "rgba(255, 255, 0, 0.8)" },
  { nombre: "Ansiedad", color: "rgba(255, 165, 0, 0.8)" },
  { nombre: "Miedo", color: "rgba(150, 75, 200, 0.8)" },
  { nombre: "Amor", color: "rgba(255, 20, 147, 0.8)" },
  { nombre: "Paz", color: "rgba(0, 255, 255, 0.8)" },
];

const EstadoUsuario = ({enfocarMapa, onSave,canOpen, toggle, isVisible}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [seleccion, setSeleccion] = useState(0);
  const [size, setSize] = useState(5);

  const handleToggle = () => {
    if (canOpen) {
      setIsOpen(!isOpen); // Alterna el estado local
      toggle(); // Notifica al componente principal
    }
  };

  const emocionActual = emociones[seleccion];

  const cambiarEmocion = (direccion) => {
    const nuevoIndex = (seleccion + direccion + emociones.length) % emociones.length;
    setSeleccion(nuevoIndex);
  };

  const handleSizeChange = (e, newValue) => {
    setSize(newValue);
  };

  const handleSave = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const intensidad = size;
          if (latitude && longitude) {
            enfocarMapa(latitude, longitude, 20); // Cambia el zoom a 15 o el valor que desees
          } else {
            console.warn("Ubicación no conseguida");
          }
          onSave(emocionActual.color, size * 4, intensidad, latitude, longitude);
          setIsOpen(false);
        },
        (error) => {
          console.error("No se pudo obtener la ubicación:", error);
          const intensidad = size;
          onSave(emocionActual.color, size * 4, intensidad, null, null);
          setIsOpen(false);
        }
      );
    } else {
      const intensidad = size;
      onSave(emocionActual.color, size * 4, intensidad, null, null);
      setIsOpen(false);
    }
  };

  return (
    <>
      {isVisible && ( // Solo renderiza si isVisible es true
        <div
          onClick={() =>  handleToggle()}
          style={{
            position: "fixed",
            top: "50%",
            right: 0,
            width: "40px",
            height: "80px",
            backgroundColor: "#888",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "10px 0 0 10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            transform: "translateY(-50%)",
          }}
        >
          {/* {isOpen ? ">" : "<"} */}
          {isOpen ? "▸" : "◂"}
          
        </div>
    )}

      <div
        style={{
          position: "fixed",
          top: "0",
          right: isOpen ? "0" : "-300px",
          width: "300px",
          height: "100vh",
          backgroundColor: emocionActual.color.replace("0.8", "0.2"), // Fondo translúcido dinámico
          transition: "right 0.3s ease",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          zIndex: 999,
          backdropFilter: "blur(10px)", // Efecto translúcido
        }}
      >
        <div
          style={{
            height: "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PuntoFlotante
            color={emocionActual.color}
            size={size * 20}
            animationDuration={3 + Math.random() * 2}
            hasParticles={true} // Activar partículas
            isAbsolute={true}
          />
        </div>

        <div
          style={{
            padding: "10px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <p style={{ margin: "0 0 10px", textAlign: "center", color: "#fff" }}>
            Intensidad
          </p>
          <Slider
            value={size}
            onChange={handleSizeChange}
            min={1}
            max={10}
            step={0.1}
            valueLabelDisplay="auto"
            style={{ color: "#fff" }}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            padding: "20px 0",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          <button
            onClick={() => cambiarEmocion(-1)}
            style={{
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              marginRight: "10px",
              color: "#fff",
            }}
          >
            {"<"}
          </button>
          {emocionActual.nombre}
          <button
            onClick={() => cambiarEmocion(1)}
            style={{
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              marginLeft: "10px",
              color: "#fff",
            }}
          >
            {">"}
          </button>
        </div>

        <div style={{ padding: "20px", textAlign: "center" }}>
          <button
            onClick={() => {
              handleSave(); // Ejecuta la primera función
              handleToggle(); // Ejecuta la segunda función
            }}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#333",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Aplicar
          </button>
        </div>
      </div>
    </>
  );
};

export default EstadoUsuario;
