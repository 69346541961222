import React, { useState, useEffect, useMemo } from 'react';
import Map, { Marker } from "react-map-gl";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
// import PuntoFlotante from "./components/PuntoFlotante";
import EstadoUsuario from "./screens/EstadoUsuario";
import "mapbox-gl/dist/mapbox-gl.css";

import ListaEstadisticas from "./components/ListaEstadisticas";
import { Source, Layer } from 'react-map-gl';
import BotonUbicacion from "./components/BotonUbicacion";
import OndaTemporal from "./components/OndaTemporal";


const MAPBOX_TOKEN = "pk.eyJ1IjoibWZlcmlhY2VycmFkYSIsImEiOiJjbTRibHFzN3owMXgxMmpyM2kybmQ5M3NmIn0._5C1j8JI-HjlC5rMz-os7w"; // Reemplaza con tu token de Mapbox

const isProduction = process.env.NODE_ENV === 'production';
// Configurar la URL base según el entorno
const apiBaseUrl = isProduction
? process.env.REACT_APP_API_URL_PROD
: process.env.REACT_APP_API_URL_DEV;

// const socket = io(" https://ae30-5-154-88-61.ngrok-free.app"); // Ajusta la URL del servidor backend
// const socket = io("http://localhost:3001"); // Ajusta la URL del servidor backend
const socket = io(apiBaseUrl); // Ajusta la URL del servidor backend
//probamos a aver si hace algo nuevo

function App() {
  const [viewState, setViewState] = useState({
    latitude: 40,
    longitude: 0,
    zoom: 2,
  });
  const [filtroColorUsuarios, setFiltroColorUsuarios] = useState(null);

  // const [estadisticas] = useState(estadisticasFalsas);
  const [usuarios, setUsuarios] = useState([]);
  const [userId, setUserId] = useState(null);
  // const [estadisticas, setEstadisticas] = useState(null); // Guardar las estadísticas calculadas

  // Lógica para asegurarnos de que solo una pantalla
  const [pantallaActiva, setPantallaActiva] = useState(null); // Controla qué pantalla está abierta

  const togglePantalla = (pantalla) => {
    setPantallaActiva((prev) => (prev === pantalla ? null : pantalla));
  };

  // Generar o recuperar el userId
  useEffect(() => {
    let storedId = localStorage.getItem("userId");
    if (!storedId) {
      storedId = uuidv4();
      localStorage.setItem("userId", storedId);
    }
    setUserId(storedId);
  }, []);

  // Escuchar actualizaciones de ubicaciones desde el servidor
  useEffect(() => {
    socket.on("locations", (data) => {
      console.log("Nuevos usuarios recibidos:", data);
      setUsuarios(data);
    });

    return () => {
      socket.off("locations");
    };
  }, []);

  // Una vez que tengamos un userId, pedimos las ubicaciones actuales
  useEffect(() => {
    if (userId) {
      socket.emit("request_locations");
    }
  }, [userId]);

  const geoJsonData = useMemo(() => {
    const usuariosFiltrados = filtroColorUsuarios
      ? usuarios.filter((usuario) => usuario.color === filtroColorUsuarios)
      : usuarios;
  
    const maxSize = Math.max(...usuariosFiltrados.map((u) => u.size), 10); // Evitar división por 0
    return {
      type: "FeatureCollection",
      features: usuariosFiltrados.map((usuario) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [usuario.lng, usuario.lat],
        },
        properties: {
          color: usuario.color,
          size: (usuario.size / maxSize) * 10, // Escala entre 0 y 10
        },
      })),
    };
  }, [usuarios, filtroColorUsuarios]);
  
  
  

  const enviarUbicacion = (color, size,intensidad, lat, lng) => {
    if (!userId) return;
    socket.emit("update_location", {
      userId,
      lat,
      lng,
      color,
      size,
      intensidad,
    });
  };

  const actualizarFiltroColor = (color) => {
    setFiltroColorUsuarios(color === filtroColorUsuarios ? null : color);
  };

    // Función para enfocar el mapa
    const enfocarMapa = (latitude, longitude, zoom) => {
      console.log("Enfocando mapa en:", { latitude, longitude, zoom }); // Para depuración
      setViewState((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        zoom,
      }));
    };
    

  return (
    <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
      <EstadoUsuario enfocarMapa={enfocarMapa} onSave={enviarUbicacion} canOpen={pantallaActiva === null || pantallaActiva === "derecha"} // Solo se abre si está permitido
        toggle={() => togglePantalla("derecha")} isVisible={pantallaActiva === null || pantallaActiva === "derecha"}  />
      {/* Llamada al componente */}
      {/* Lista de estadísticas */}
      <ListaEstadisticas enfocarMapa={enfocarMapa} actualizarFiltroColor={actualizarFiltroColor} canOpen={pantallaActiva === null || pantallaActiva === "izquierda" } // Solo se abre si está permitido
        toggle={() => togglePantalla("izquierda")} isVisible={pantallaActiva === null || pantallaActiva === "izquierda"}  />
      {/* Onda temporal */}
      <OndaTemporal canOpen={pantallaActiva === null || pantallaActiva === "inferior"} // Solo se abre si está permitido
        toggle={() => togglePantalla("inferior")} isVisible={pantallaActiva === null || pantallaActiva === "inferior"} />
      {/* Mapa */}
      <Map
        initialViewState={viewState}
        {...viewState} // Usar viewState directamente
        onMove={(evt) => setViewState(evt.viewState)}
        // onViewStateChange={(e) => setViewState(e.viewState)}
        // onMove={(evt) => setViewState(evt.viewState)}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <Source id="usuarios" type="geojson" data={geoJsonData}>
          <Layer
            id="puntos-usuarios"
            type="circle"
            paint={{
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["get", "size"],
                0, 5,    // Si el size es 0, el radio será 5
                10, 20,  // Si el size es 10, el radio será 20
              ],
              "circle-color": ["get", "color"],
              "circle-opacity": 0.4,
              "circle-blur": 0.7, // Ajusta este valor para definir el nivel de desenfoque
            }}
          />
        </Source>
      </Map>
      <BotonUbicacion enfocarMapa={enfocarMapa} />
    </div>
  );
  
}

export default App;
