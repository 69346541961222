import React, { useState, useEffect } from "react";
import { emociones } from "../screens/EstadoUsuario";

const backendUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;


const OndaTemporal = ({canOpen,toggle,isVisible}) => {
  const [onda, setOnda] = useState([]); // Datos de la onda temporal
  const [isOpen, setIsOpen] = useState(false); // Estado del panel desplegable
  const [intervalo, setIntervalo] = useState("6m"); // Intervalo seleccionado
  const [hoverInfo, setHoverInfo] = useState(null); // Para almacenar la información al hover


  
  const handleToggle = () => {
    if (canOpen) {
      setIsOpen(!isOpen); // Alterna el estado local
      toggle(); // Notifica al componente principal
    }
  };

  const resoluciones = {
    "6m": "2w",
    "3m": "1w",
    "1m": "3d",
    "1w": "1d",
    "1d": "1h",
  };

  const fetchOndaTemporal = async (intervalo) => {
    const resolucion = resoluciones[intervalo];
    try {
      const response = await fetch(
        // `/api/ondas?intervalo=${intervalo}&resolucion=${resolucion}`
        `${backendUrl}/api/ondas?intervalo=${intervalo}&resolucion=${resolucion}`
      );
      if (!response.ok) {
        throw new Error("Error al obtener la onda temporal.");
      }
      const data = await response.json();
      setOnda(data.onda || []);
    } catch (error) {
      console.error("Error al cargar la onda temporal:", error);
    }
  };

  const handleMouseMove = (event) => {
    const svgRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - svgRect.left;
    const ancho = window.innerWidth;
    const pasoX = ancho / (onda.length - 1);
  
    // Ajustar el índice considerando el desplazamiento al centro del intervalo
    const index = Math.floor((mouseX + pasoX / 2) / pasoX);
    if (index >= 0 && index < onda.length) {
      const segmento = onda[index];
      const fecha = new Date(segmento.inicio);
      setHoverInfo({
        fecha: isNaN(fecha.getTime())
          ? "N/A"
          : fecha.toLocaleString("es-ES", { dateStyle: "short", timeStyle: "short" }),
        intensidad: segmento.intensidad.toFixed(0), // Limitar a 2 decimales
        emocion: emociones.find((e) => e.color === segmento.colorDominante)?.nombre || "Desconocida", // Buscar el nombre
        x: event.clientX, // Coordenada X del ratón
        y: event.clientY, // Coordenada Y del ratón
      });
    } else {
      setHoverInfo(null); // Si el ratón está fuera de la onda
    }
  };
  
  
  useEffect(() => {
    fetchOndaTemporal(intervalo);
  }, [intervalo]);

  const generarCaminoArea = () => {
    if (onda.length <= 1) return ""; // Asegúrate de que haya al menos 2 puntos
  
    const ancho = window.innerWidth;
    const altura = 200; // Altura máxima del SVG
    const pasoX = ancho / (onda.length - 1); // Espaciado horizontal entre puntos
  
    // Normalizar intensidades
    const intensidades = onda.map(segmento => segmento.intensidad);
    const maxIntensidad = Math.max(...intensidades); // Encuentra la intensidad máxima
    const minIntensidad = Math.min(...intensidades); // Encuentra la intensidad mínima
    const rango = maxIntensidad - minIntensidad || 1; // Evitar división por 0
  
    // Mapeo de intensidades al rango [0, altura]
    const normalizar = intensidad =>
      altura - ((intensidad - minIntensidad) / rango) * altura;
  
    let path = `M 0,${altura} `; // Comienza en la esquina inferior izquierda
  
    onda.slice(0, -1).forEach((segmento, index) => {
      const x1 = index * pasoX; // Punto actual en X
      const y1 = normalizar(segmento.intensidad); // Punto actual en Y (normalizado)
      const x2 = (index + 1) * pasoX; // Siguiente punto en X
      const y2 = normalizar(onda[index + 1]?.intensidad || 0); // Siguiente punto en Y (normalizado)
  
      // Punto de control para suavizar
      const controlX = (x1 + x2) / 2; // Punto intermedio entre los dos puntos
      const controlY1 = y1; // Mantener la misma altura para el control
      const controlY2 = y2; // Mantener la misma altura para el control
  
      if (index === 0) {
        path += `L ${x1},${y1} `; // Línea recta al primer punto
      }
      path += `C ${controlX},${controlY1} ${controlX},${controlY2} ${x2},${y2} `;
    });
  
    path += `L ${ancho},${altura} Z`; // Cierra el área en la esquina inferior derecha
    return path;
  };

  const generarEtiquetasFecha = () => {
    const ancho = window.innerWidth;
    const pasoX = ancho / (onda.length - 1); // Espaciado entre etiquetas
  
    return onda.map((segmento, index) => {
      const x = (index+1) * pasoX;
  
      // Formatear la fecha o la hora
      let etiqueta;
      if (intervalo === "1d") {
        // Mostrar horas si el intervalo es "1d"
        const hora = new Date(segmento.inicio).getHours();
        etiqueta = `${hora}:00`;
      } else {
        // Mostrar fechas en otros intervalos
        const date = new Date(segmento.inicio);
        etiqueta = isNaN(date.getTime())
          ? "N/A"
          : date.toLocaleDateString("es-ES", { month: "short", day: "numeric" });
      }
  
      return (
        <text
          key={index}
          x={x}
          y={20} // Posición Y (encima del gráfico)
          textAnchor="middle"
          style={{
            fontSize: "10px", // Ajusta el tamaño del texto
            fill: "#fff", // Color del texto
            userSelect: "none",
          }}
        >
          {etiqueta}
        </text>
      );
    });
  };

  const generarCaminoAreaEspejo = () => {
    if (onda.length <= 1) return ""; // Asegúrate de que haya al menos 2 puntos
  
    const ancho = window.innerWidth;
    const altura = 100; // Altura máxima para cada mitad (superior e inferior)
    const centro = altura; // Línea central (base de la simetría)
    const pasoX = ancho / (onda.length - 1); // Espaciado horizontal entre puntos
  
    // Normalizar intensidades
    const intensidades = onda.map(segmento => segmento.intensidad);
    const maxIntensidad = Math.max(...intensidades); // Encuentra la intensidad máxima
    const minIntensidad = Math.min(...intensidades); // Encuentra la intensidad mínima
    const rango = maxIntensidad - minIntensidad || 1; // Evitar división por 0
  
    // Mapeo de intensidades al rango [0, altura]
    const normalizar = intensidad =>
      ((intensidad - minIntensidad) / rango) * altura;
  
    let path = `M 0,${centro} `; // Comienza en el centro vertical de la izquierda
  
    // Crear el camino de la onda superior
    onda.forEach((segmento, index) => {
      const x1 = index * pasoX; // Punto actual en X
      const y1 = centro - normalizar(segmento.intensidad); // Punto actual en Y (mitad superior)
      const x2 = (index + 1) * pasoX; // Siguiente punto en X
      const y2 = centro - normalizar(onda[index + 1]?.intensidad || 0); // Siguiente punto en Y (mitad superior)
  
      // Punto de control para suavizar
      const controlX = (x1 + x2) / 2; // Punto intermedio entre los dos puntos
      const controlY1 = y1; // Mantener la misma altura para el control
      const controlY2 = y2; // Mantener la misma altura para el control
  
      if (index === 0) {
        path += `L ${x1},${y1} `; // Línea recta al primer punto
      }
      path += `C ${controlX},${controlY1} ${controlX},${controlY2} ${x2},${y2} `;
    });
  
    // Crear el camino de la onda inferior (reflejada)
    const reversedOnda = onda.slice().reverse(); // Invertir la onda para reflejarla
    reversedOnda.forEach((segmento, index) => {
        const x1 = (onda.length - 1 - index) * pasoX; // Punto actual en X
        const y1 = centro + normalizar(segmento.intensidad); // Reflejar la intensidad hacia abajo
        const x2 =
        index < reversedOnda.length - 1
            ? (onda.length - 2 - index) * pasoX // Siguiente punto en X
            : 0; // Cierra la curva en el inicio
        const y2 =
        index < reversedOnda.length - 1
            ? centro + normalizar(reversedOnda[index + 1]?.intensidad || 0) // Reflejar la intensidad hacia abajo
            : centro; // Cierra la curva en el centro

        // Punto de control para suavizar
        const controlX = (x1 + x2) / 2; // Punto intermedio entre los dos puntos
        const controlY1 = y1; // Mantener la misma altura para el control
        const controlY2 = y2; // Mantener la misma altura para el control

        path += `C ${controlX},${controlY1} ${controlX},${controlY2} ${x2},${y2} `;
  });

  path += `Z`; // Cierra el área
  return path;
};
  
  
  
  
  
  const generarGradientes = () => {
    return (
      <linearGradient id="gradiente-onda" x1="0%" y1="0%" x2="100%" y2="0%">
        {onda.map((segmento, index) => (
          <stop
            key={index}
            offset={`${(index / onda.length) * 100}%`}
            stopColor={segmento.colorDominante}
          />
        ))}
      </linearGradient>
    );
  };

  const generarCurvaBlanca = () => {
    if (onda.length === 0) return "";

    const ancho = window.innerWidth;
    const altura = 200;
    const pasoX = ancho / onda.length;

    return onda
      .map((segmento, index) => {
        const x = index * pasoX;
        const y = altura - segmento.intensidad;
        return `${index === 0 ? "M" : "L"} ${x},${y}`;
      })
      .join(" ");
  };

  return (
    <>
      {/* Botón para desplegar el panel */}
      {isVisible && ( // Solo renderiza si isVisible es true
        <div
          onClick={() =>handleToggle()}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            width: "80px",
            height: "40px",
            backgroundColor: "#888",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "10px 10px 0 0",
            zIndex: 1000,
          }}
        >
          {isOpen ? "▾" : "▴"}
        </div>
      )}
      {/* Panel de la onda temporal */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: "0",
            width: "100%",
            height: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 999,
            padding: "10px",
            overflow: "hidden",
          }}
        >


          {/* SVG de la onda temporal */}
          <svg width="100%" height="200" padding="20px" onMouseMove={handleMouseMove}
            onMouseLeave={() => setHoverInfo(null)}  >
            <defs>
                <linearGradient id="gradiente-onda" x1="0%" y1="0%" x2="100%" y2="0%">
                {onda.map((segmento, index) => (
                    <stop
                    key={index}
                    offset={`${(index / (onda.length - 1)) * 100}%`}
                    stopColor={segmento.colorDominante}
                    />
                ))}
                </linearGradient>
                <filter id="desenfoque">
                <feGaussianBlur in="SourceGraphic" stdDeviation="6" /> {/* Ajusta el nivel de desenfoque */}
                </filter>
            </defs>

            {/* Área coloreada con desenfoque */}
            <path
                d={generarCaminoArea()}
                fill="url(#gradiente-onda)"
                style={{
                filter: "url(#desenfoque)", // Aplica el filtro de desenfoque
                transition: "d 0.3s",
                }}
            />
            {/* Etiquetas de fecha u hora */}
            {generarEtiquetasFecha()}
            {/* {onda.slice(1).map((segmento, index) => {
                const ancho = window.innerWidth;
                const pasoX = ancho / (onda.length - 1);
                const x = (index + 1) * pasoX; // Ajustamos el índice para empezar desde el segundo punto

                // Convertir "inicio" a una fecha formateada
                let fecha;
                if (segmento.inicio) {
                const date = new Date(segmento.inicio);
                fecha = isNaN(date.getTime()) ? "N/A" : date.toLocaleDateString("es-ES", { month: "short", day: "numeric" });
                } else {
                fecha = "N/A"; // Si no hay inicio
                }

                return (
                <text
                    key={index}
                    x={x}
                    y={20} // Posición Y (encima del gráfico)
                    textAnchor="middle" // Centramos el texto en la posición X
                    style={{
                    fontSize: "12px",
                    fill: "#fff", // Color del texto
                    userSelect: "none",
                    }}
                >
                    {fecha}
                </text>
                );
            })} */}
            </svg>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* <label style={{ marginBottom: "10px", color: "#fff", fontSize: "16px" }}>
                    Intervalo Temporal:
                </label> */}
                <input
                    type="range"
                    min="0"
                    max="4"
                    value={["6m", "3m", "1m", "1w", "1d"].indexOf(intervalo)}
                    onChange={(e) =>
                    setIntervalo(["6m", "3m", "1m", "1w", "1d"][parseInt(e.target.value)])
                    }
                    style={{
                    width: "80%",
                    marginBottom: "10px",
                    accentColor: "#888", // Color del slider
                    }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                    {["6m", "3m", "1m", "1w", "1d"].map((label, index) => (
                    <span
                        key={index}
                        style={{
                        fontSize: "12px",
                        color: intervalo === label ? "#fff" : "#888", // Resalta el seleccionado
                        }}
                    >
                        {label === "6m"
                        ? "6 meses"
                        : label === "3m"
                        ? "3 meses"
                        : label === "1m"
                        ? "1 mes"
                        : label === "1w"
                        ? "1 semana"
                        : "Hoy"}
                    </span>
                    ))}
                </div>
            </div>
            {hoverInfo && (
              <div
                style={{
                  position: "fixed",
                  top: hoverInfo.y + 10, // Por defecto, 10px debajo del ratón
                  left:
                    hoverInfo.x > window.innerWidth - 100 // Si está cerca del borde derecho
                      ? hoverInfo.x - 80 // Mostrar hacia la izquierda
                      : hoverInfo.x + 10, // Mostrar hacia la derecha por defecto
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  pointerEvents: "none", // Evita que interfiera con el ratón
                  whiteSpace: "nowrap", // Evita que el texto se corte
                }}
              >
                <div>{hoverInfo.fecha}</div>
                <div>Intensidad: {hoverInfo.intensidad}</div>
                <div>Emoción: {hoverInfo.emocion}</div> {/* Mostrar la emoción */}
              </div>
            )}


        </div>
      )}
    </>
  );
};

export default OndaTemporal;
